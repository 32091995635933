<template>
    <div> Quality Control</div>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>